import React from "react";

const Header = () => {
  return (
    <header className="px-3 md:flex md:justify-evenly md:items-center">
      <section className="my-8">
        <img src="/images/logo-nw.png" alt="Northwestern Medicine" />
      </section>
      <section className="my-8">
        <img src="/images/logo-mnstr.png" alt="MNSTR Logo" />
      </section>
    </header>
  );
};

export default Header;
