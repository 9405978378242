import React from "react";

const ThankYou = () => {
  return (
    <main className="px-3 my-8">
      <h1 className="text-3xl font-bold">Thanks!</h1>
      <p className="bg-[#d4edda] text-[#186429] border-2 p-4 my-8 border-[#bfe5c7] md:max-w-xl">
        Your Contact Was Submitted Successfully.
      </p>
      <h2 className="text-[#2fcaf8] text-3xl">Visit Us At Any Time</h2>
    </main>
  );
};

export default ThankYou;
