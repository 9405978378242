/**
 * The select component which dynamically handles different select options
 * it also gets register and errors to handle the form state
 */

import React from "react";

const Select = ({ id, label, register, errors, data }) => {
  return (
    <div className="flex mb-2 flex-col">
      <div className="flex">
        <label htmlFor={id} className="mb-1 font-semibold w-2/6">
          {label} *
        </label>
        <select
          name={id}
          id={id}
          className="p-2 text-black w-4/6"
          {...register(id)}
        >
          <option disabled value="" selected>
            please select a value
          </option>
          {data.map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </select>
      </div>
      {errors[`${id}`] && (
        <p className="text-red-600">{errors[`${id}`]?.message}</p>
      )}
    </div>
  );
};

export default Select;
