import React from "react";

const Footer = () => {
  return (
    <footer className="px-3 md:flex md:justify-evenly md:items-center">
      <section className="my-8 flex justify-center items-center flex-col">
        <h3 className="bg-white text-[#f67e93] px-6 py-4 text-2xl text-center font-bold rounded-[32px]">
          In Collaboration With
        </h3>
        <a href="https://www.nm.org/">
          <img src="/images/logo-nw.png" />
        </a>
      </section>
      <section className="my-8 flex justify-center items-center flex-col">
        <h3 className="bg-white text-[#f67e93] px-6 py-4 text-2xl text-center font-bold rounded-[32px]">
          Educational Partner
        </h3>
        <a href="https://plusone.expert/">
          <img src="/images/p1.png" />
        </a>
      </section>
    </footer>
  );
};

export default Footer;
