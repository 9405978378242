/**
 * Checkbox component which handles the checkbox input in the form
 * it recieves the register function and the errors object from the useForm hook
 * and attach them to the input
 */

import React from "react";

const CheckBox = ({ register, errors }) => {
  return (
    <div className="flex flex-row mb-2 gap-4 text-black">
      <input
        type="checkbox"
        name="checkBoxValue"
        id="checkBoxValue"
        className="p-2 text-black"
        {...register("checkBoxValue")}
      />
      <label htmlFor="checkBoxValue" className="mb-1 font-semibold">
        Stay up to date with emails about special offers, new courses, events
        and training opportunities
      </label>
      {errors.checkBoxValue && <p>{errors.checkBoxValue?.message}</p>}
    </div>
  );
};

export default CheckBox;
