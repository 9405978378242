import React from "react";

const Billboard = () => {
  return (
    <article className="my-8 md:flex md:justify-evenly md:items-center">
      <section className="my-8">
        <img src="/images/btrfly.png" alt="Butterfly" />
      </section>
      <section className="my-8 text-center">
        <h1 className="text-[#6C757D] text-3xl font-bold my-2">
          Egyptian Presidential Initiative
          <br />
          <span className="text-[#f67e93]">For Cancer Education</span>
          <br />
          <span className="text-[#f67e93] font-light">&#40;EPIC&#41;</span>
        </h1>
        <p className="text-[#6C757D] text-2xl font-semibold my-2">
          In Collaboration with Northwestern University Feinberg School of
          Medicine
        </p>
        <h2 className="text-[#6C757D] font-bold text-5xl my-2">
          The First of its Kind
        </h2>
      </section>
    </article>
  );
};

export default Billboard;
