import React from "react";

const Programs = () => {
  return (
    <article className="my-8 md:flex md:justify-evenly md:items-center">
      <section className="my-8 md:w-2/3">
        <h3 className="p-12 text-[#6C757D] text-2xl font-semibold border-4 border-[#f67e93] rounded-[72px] text-center border-l-0 rounded-tl-none rounded-bl-none">
          This program made available through the support of
        </h3>
      </section>
      <section className="my-8 md:w-1/3">
        <h3 className="my-2 text-center text-[#6C757D] text-3xl font-semibold">
          Novartis
        </h3>
        <h3 className="my-2 text-center text-[#6C757D] text-3xl font-semibold">
          Pfizer
        </h3>
        <h3 className="my-2 text-center text-[#6C757D] text-3xl font-semibold">
          Roche
        </h3>
      </section>
    </article>
  );
};

export default Programs;
