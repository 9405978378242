import React from "react";
import Partner from "./Partner";

const PartnersData = [
  {
    image: "/images/nwuni.png",
    adv: "",
    title:
      "Partner Institute: Northwestern University Feinberg School of Medicine",
    desc: "Ranks among top medical schools in the nation for 13 consecutive years, reaching 8th position between the best 100 cancer centers in 2021",
  },
  {
    image: "/images/wgj.png",
    adv: "MEDICAL ONCOLOGY DISCIPLINE LEAD & PROJECT LEAD",
    title: "William J. Gradishar MD FASCO FACP",
    desc: "Betsy Bramsen Professor of Breast Oncology & Professor of Medicine Chief, Division of Hematology/Oncology Deputy Director, Clinical Network Director, Maggie Daley Center for Women's Cancer Care Robert H. Lurie Comprehensive Cancer Center of Northwestern University - Feinberg School of Medicine Chair, NCCN guidelines for Breast cancer",
  },
];

const Partners = () => {
  return (
    <article className="my-8">
      <section className="my-8">
        <h3 className="bg-[#6C757D] p-4 rounded-[64px] text-white font-bold text-3xl text-center">
          EPIC Program Key Partners
        </h3>
      </section>
      <section className="my-8">
        {PartnersData.map((partner) => (
          <Partner
            key={partner.title}
            image={partner.image}
            adv={partner.adv}
            title={partner.title}
            desc={partner.desc}
          />
        ))}
      </section>
    </article>
  );
};

export default Partners;
