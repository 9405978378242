import React from "react";

const Certificates = () => {
  return (
    <article className="my-8 bg-[#b3b7bb] p-6">
      <section className="my-8">
        <h3 className="bg-white text-[#f67e93] px-6 py-4 text-2xl text-center font-bold rounded-[32px]">
          Get Your certificate from NorthWestern University
        </h3>
      </section>
      <section className="my-8 md:flex md:justify-evenly md:items-center">
        <div className="my-2">
          <img src="/images/ct1.png" className="w-full" />
        </div>
        <div className="my-2">
          <img src="/images/ct2.png" className="w-full" />
        </div>
        <div className="my-2">
          <img src="/images/3w3.png" className="w-full" />
        </div>
      </section>
    </article>
  );
};

export default Certificates;
