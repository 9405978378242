import React from "react";

const Doctor = ({ image, title, name, desc }) => {
  return (
    <div className="my-8 flex justify-center items-center flex-col md:w-[50%]">
      <img src={image} />
      <h3 className="my-2 p-2 bg-[#f67e93] text-white text-xl font-semibold">{title}</h3>
      <h3 className="my-2 text-[#6C757D] text-2xl font-semibold">{name}</h3>
      <p className="my-2 text-[#6C757D] text-center">{desc}</p>
    </div>
  );
};

export default Doctor;
