import Home from './pages/Home';
import { Routes, Route } from 'react-router-dom';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ThankYou from './pages/ThankYou';
import SubmitVideo from './pages/SubmitVideo';
import CookieConsent from './components/CookieConsent';
import { useCookies } from 'react-cookie';
import ReactGA from 'react-ga4';

// ReactGA.initialize('G-');

function App() {
  const [cookies] = useCookies(['cookieConsent']);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="/submit-video" element={<SubmitVideo />} />
      </Routes>
      {!cookies.cookieConsent && <CookieConsent />}
    </>
  );
}

export default App;
