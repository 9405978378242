import React from "react";

const Privacy = ({ title, desc }) => {
  return (
    <section className="my-8">
      <h2 className="text-[#2fcaf8] text-3xl my-4">{title}</h2>
      <p className="md:max-w-5xl">{desc}</p>
    </section>
  );
};

export default Privacy;
