/**
 * The video component which handles the logic of uploading a video file to the form
 * it recieves the selectedFile state, fileRef, loading, currentSize, maxSize
 */

import React from 'react';

const Video = ({ setSelectedFile, selectedFile, fileRef, loading, currentSize, maxSize }) => {
  /**
   * This function resets the file input field
   * when the user clicks it
   */
  const handleCancel = () => {
    setSelectedFile(null);
    fileRef.current.value = null;
  };

  return (
    <div className="flex flex-col mb-2 items-start">
      <label className="mb-1 font-semibold" htmlFor="video">
        Video *
      </label>
      <p className="text-black my-2">
        According to the instructions received recently from the Presidential Initiative for Women Health, you
        are kindly requested to provide the following information:
      </p>
      <ul className="list-disc px-10 my-2">
        <li className="text-black">
          In a 30-60 seconds video, Introduce yourself and tell us why you want to apply to this diploma
          program.
        </li>
      </ul>
      <p className="text-black my-2">
        Please be informed that this video is a mandatory requirement for being shortlisted and for enrolment
        purposes.
      </p>
      <input
        type="file"
        name="video"
        id="video"
        onChange={(e) => setSelectedFile(e.target.files[0])}
        className="text-black"
        required
        ref={fileRef}
        accept="video/*"
      />
      {selectedFile && (
        <button className="text-red-500" onClick={handleCancel}>
          Cancel
        </button>
      )}
      {loading && <p>Uploaded {Math.floor((currentSize / maxSize) * 100)}%</p>}
    </div>
  );
};

export default Video;
