import React from "react";
import Privacy from "../components/Privacy";

const PrivacyNotices = [
  {
    title: "PlusOne safeguards your personal privacy",
    desc: "PlusOne respects your privacy and is committed to maintaining a high level of security regarding your personal data. The Company is also committed to ensuring that processing is carried out in accordance with applicable data protection legislation.",
  },
  {
    title: "Data Controller",
    desc: "The Company is the data controller of your personal data and is therefore responsible for ensuring that your personal data is processed correctly and securely in accordance with applicable legislation.",
  },
  {
    title: "Which personal data does the Company process?",
    desc: "Personal data means any information that directly or indirectly relates to a natural, living person. Accordingly, personal data is information about you and your person, e.g. your name, age, gender, address and phone number. Processing means any operation which is performed on personal data, such as collection, storage, use, adaption or disclosure.",
  },
  {
    title: "Visitors of the Company's website",
    desc: "In connection with visits to the Company's website, the Company collects the following information about you that the Company needs to be able to improve, streamline, simplify and develop our website. Technical data, such as IP address, MAC address, URL, unique device ID, network and device performance, browser, language and identification settings, geographic location, operating system, other information from cookies or similar mechanisms (device information).",
  },
  {
    title: "From where do the company collect your personal data?",
    desc: "With respect to Users and Experts and potential Experts, your personal data is usually collected directly from you. Regarding existing or potential suppliers, partners and affiliates or its company representatives, your personal data is usually collected from the company or organization that you represent, but also, in certain cases, directly from you, e.g. by email, our social media or other channels of communication or in connection with events or meetings. This is also the case if you are an employee/member at a company/organization that has registered a business account at the website.",
  },
  {
    title: "The Company's processing of your personal data",
    desc: "The purposes for which the Company intends to process your personal data and the legal basis for the respective processing activities are in compliance with The General Data Protection Regulation (GDPR).",
  },
  {
    title: "How long does the Company store your personal data?",
    desc: 'Your personal data is stored as long as there is a need to preserve them in order to fulfil the purposes for which the data was collected in accordance with this Privacy Notice Thereafter, your personal data will be deleted. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies. Some personal data will, for the purpose of complying with applicable accounting legislation, be stored for ten years, counting from the end of the calendar year during which the financial year, to which the information pertained, was terminated. Contact information regarding company representatives is stored during such time the Company considers that the information is necessary to maintain the relationship with the company/organization. Deletion shall take place when the Company becomes aware that the information is no longer adequate or relevant for the purpose, or at the request of the contact person. For more information about how long the Company stores specific personal data, please contact the Company. Contact information is provided under section "Contact Information" below.',
  },
  {
    title: "With whom does the Company share your personal data?",
    desc: "The Company does not disclose personal data to third parties, except when necessary to fulfil a legal obligation or to fulfil the Company's obligations to you and/or partners.",
  },
  {
    title: "Transfer of personal data to third countries",
    desc: "The Company may transfer your personal data to countries outside the EU. If personal data is transferred to a country outside the EU, the Company will take measures to ensure that the personal data continues to be protected and will also take the necessary measures to ensure a legal transfer of the personal data to countries outside the EU. Such necessary measures consist of ensuring that the third country to which the personal data is transferred, is subject to a decision from the European Commission that it ensures an adequate level of protection or taking appropriate protection measures.",
  },
  {
    title: "Your privacy rights",
    desc: "As the data controller, the Company is responsible for ensuring that your personal data is processed in accordance with applicable legislation. The Company will, at your request or on its own initiative, rectify, erase or complete any information found to be inaccurate, incomplete or misleading. You have the right to request access to and rectification or erasure of your personal data (e.g., if such erasure is required by applicable law), request restriction of the processing of your personal data and object to the processing, as permitted by applicable personal data legislation (e.g. if you contest the accuracy of the personal data or if the processing is unlawful but you oppose the erasure of the personal data and request restriction of its use instead). Under certain conditions, you have the right to data portability, i.e., a right to receive your personal data in a structured, commonly used and machine-readable format and the right to transmit those data to another controller. If you do not want the Company to process your personal data for direct marketing purposes, you have the right to object to such processing at any time. When the Company has received your objection, the Company will cease the processing of your personal data for such marketing purposes. You have the right, through a written and signed application, to obtain free of charge a register extract from the Company regarding which personal data are stored about you, the purposes of the processing and to which recipients the data has been or shall be transferred. You also have the right to obtain information about the envisaged period for which the personal data will be stored or the criteria used to determine this period. You also have the right to receive information about your other rights as specified in this paragraph “Your Privacy Rights”.",
  },
  {
    title: "Security of your personal data",
    desc: "You should always be able to feel safe when you provide us with your personal data. Therefore, the Company has implemented the security measures that are necessary to protect your personal data against unauthorized access, alteration and destruction. The Company will not disclose your personal data, other than as expressly provided by this Privacy Policy. The Company encrypts your data in transit and at rest on its servers, and all private communication with your Expert is end-to-end encrypted.",
  },
  {
    title: "Cookies Policy",
    desc: "The Company uses cookie-like techniques in order to provide certain functions on the Company's website and to improve the website and to deliver a better and more personal service. The information is stored in the form of a file comprising encrypted login data. The Company may gather and analyze information regarding usage of our website, including domain name, the number of hits, the pages visited, previous/subsequent sites visited and length of user session. This information may be gathered by using cookies. The Company uses cookies and similar technologies to track usage of the website and to address security issues. Further, the Company may also use cookies to store your preferences relating to use of our website. A cookie is a small amount of data, which often includes a unique identifier that is sent to your computer, tablet, phablet, cell phone or other electronic device (a \"Device\") browser from a website's computer and is stored on your Device's hard drive. Each website can send its own cookie to your browser if your browser's preferences allow it, but (to protect your privacy) your browser only permits a website to access the cookies it has already sent to you, not the cookies sent to you by other sites. Many sites do this whenever a user visits their website in order to track traffic flows. Cookies record information about your preferences and allow the Company to modify the Platform to your interests. During the course or any visit to the Platform, the pages you see, along with a cookie, are downloaded to your Device. Many websites do this, because cookies enable website publishers to do useful things such as finding out whether the Device (and probably its user) has visited the website before. This is done on a repeat visit by checking for the cookie left there on the previous visit. Information supplied by cookies can help the Company to analyze the profile of our visitors in order to provide you with a better user experience.",
  },
  {
    title: "reCAPTCHA",
    desc: "The Company may use reCAPTCHA which is a service provided by Google Inc. reCAPTCHA is used by the Company to protect its website from for example automated spam or other attacks.",
  },
  {
    title: "If you do not share your personal data with the company",
    desc: "If you do not share your personal data with the Company, the Company will not be able to fulfil its legal or contractual obligations towards you.",
  },
  {
    title: "Changes",
    desc: "The Company reserves the right to change this Privacy Notice at any time. In the event of changes to this Privacy Notice, the Company will publish the amended Privacy Notice on the website with information on when the changes will come into effect and may also notify customers and partners in an appropriate manner.",
  },
  {
    title: "Links",
    desc: "The Platform may contain links to other websites that are not operated by the Company. If you click on a third-party link, you will be directed to that third party's site. The Company strongly advise you to review the privacy Notice of every site you visit. The Company has no control over and assume no responsibility for the content, privacy policies or practices of any third-party sites or services.",
  },
  {
    title: "Contact information",
    desc: "Do not hesitate to contact the Company if you have any questions about this Privacy Notice, the processing of your personal data or if you wish to exercise your rights under this Privacy Notice or applicable legislation. • GERMANY OFFICE o Email: privacy@plusone.expert o Location: Friedrichstr 114a, Berlin 10117, Germany • EGYPT OFFICE o Email: privacy@plusone.expert o Location: 36A road no. 270 New Maadi, Cairo (Postal code: 11742) • Our website: https://plusone.expert/",
  },
];

const PrivacyPolicy = () => {
  return (
    <main className="px-3 my-8">
      <h1 className="text-6xl my-8">PlusOne Privacy Notice</h1>
      <article className="my-8">
        {PrivacyNotices.map((notice) => (
          <Privacy key={notice.title} title={notice.title} desc={notice.desc} />
        ))}
      </article>
    </main>
  );
};

export default PrivacyPolicy;
