import React from "react";

const Partner = ({ image, adv, title, desc }) => {
  return (
    <div className="my-8 md:flex md:justify-evenly md:items-center">
      <div className="my-8 flex justify-center md:w-2/6">
        <img src={image} />
      </div>
      <div className="my-8 md:w-4/6">
        {adv && <h3 className="bg-[#f67e93] text-white p-3 my-2">{adv}</h3>}
        <h3 className="text-[#f67e93] text-2xl my-2">{title}</h3>
        <p className="text-[#6C757D] my-2">{desc}</p>
      </div>
    </div>
  );
};

export default Partner;
