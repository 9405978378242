import React from "react";
import Detail from "./Detail";

const Stats = [
  {
    title: "5",
    desc: "Breast Cancer modules that cover all aspects of managing Breast Cancer",
  },
  {
    title: "75+",
    desc: "Hours of online educational CME accredited sessions",
  },
  {
    title: "+100",
    desc: "Educational Online sessions conducted by 100 global experts, and uploaded on MOH portal",
  },
  {
    title: "Up to 10",
    desc: "Remote, hands-on experience, interactive and live workshops for pathology, imaging and radiotherapy modules",
  },
  {
    title: "3-5",
    desc: "Surgery on demands videos for key Breast Cancer surgeries to be shoot exclusively for this program",
  },
];

const Statistics = () => {
  return (
    <article className="my-8 p-4 border-4 border-[#f67e93] rounded-[64px] text-center md:flex md:justify-evenly md:items-center">
      {Stats.map((stat) => (
        <Detail key={stat.title} title={stat.title} desc={stat.desc} />
      ))}
    </article>
  );
};

export default Statistics;
