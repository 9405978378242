import React from "react";
import Doctor from "./Doctor";

const DoctorsData = [
  {
    image: "/images/dr-smf.png",
    title: "IMAGING DISCIPLINE LEAD",
    name: "Sarah M Friedewald, MD",
    desc: "Vice Chair for Women's Imaging, Department of Radiology Chief of Breast Imaging in the Department of Radiology Associate Professor of Radiology",
  },
  {
    image: "/images/dr-nmh.png",
    title: "SURGERY DISCIPLINE LEAD",
    name: "Nora M Hansen, MD",
    desc: "Chief of Breast Surgery in the Department of Surgery Professor of Surgery",
  },
  {
    image: "/images/dr-kps.png",
    title: "PATHOLOGY DISCIPLINE LEAD",
    name: "Kalliopi P Siziopikou, MD, PhD",
    desc: "Director of Breast Pathology in the Department of Pathology Professor of Pathology",
  },
  {
    image: "/images/dr-jps.png",
    title: "RADIOTHERAPY DISCIPLINE LEAD",
    name: "Jonathan B Strauss, MD, MBA",
    desc: "Vice Chair for Education, Department of Radiation Oncology Associate Professor of Radiation Oncology and Medical Education",
  },
];

const Doctors = () => {
  return (
    <article className="my-8">
      <section className="my-8 md:flex md:justify-evenly md:items-center md:gap-1">
        <h2 className="mb-2 md:w-full p-4 text-[#f67e93] text-3xl font-semibold border-4 border-[#f67e93] rounded-[64px] text-center border-l-0 rounded-tl-none rounded-bl-none">
          Northwestern Scientific Committee:
        </h2>
        <h2 className="md:w-full p-4 text-center text-white bg-[#737374] text-3xl font-semibold rounded-[64px] rounded-tr-none rounded-br-none">
          Leading Faculty
        </h2>
      </section>
      <section className="my-8 md:flex md:justify-evenly md:items-center md:flex-wrap">
        {DoctorsData.map((doctor) => (
          <Doctor
            key={doctor.name}
            image={doctor.image}
            title={doctor.title}
            name={doctor.name}
            desc={doctor.desc}
          />
        ))}
      </section>
    </article>
  );
};

export default Doctors;
