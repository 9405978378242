import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';

const CookieConsent = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['cookieConsent']);
  const giveCookieConsent = () => {
    setCookie('cookieConsent', true);
  };

  return (
    <div className="bg-[#f7f7f7] border-4 border-[#111111] my-4 mx-auto fixed p-4 left-4 right-4 bottom-4 max-w-6xl flex justify-between items-center text-xl">
      <p>
        We use cookies to enhance your user experience. By using our website, you agree to our use of cookies.
        <Link to="/privacy-policy" className="text-emerald-600">
          {' '}
          Learn more.
        </Link>
      </p>
      <button onClick={giveCookieConsent} className="px-6 py-4 text-center font-bold bg-[#f67e93] text-white">
        Accept
      </button>
    </div>
  );
};

export default CookieConsent;
