import React from 'react';
import Header from '../components/Header';
import Billboard from '../components/Billboard';
import Registering from '../components/Registering';
import Statistics from '../components/Statistics';
import Partners from '../components/Partners';
import Doctors from '../components/Doctors';
import Certificates from '../components/Certificates';
import Programs from '../components/Programs';
import Footer from '../components/Footer';

const Home = () => {
  return (
    <>
      <Header />
      <main className="px-3">
        <Billboard />
        <Registering />
        <Statistics />
        <Partners />
        <Doctors />
        <Certificates />
        <Programs />
      </main>
      <Footer />
    </>
  );
};

export default Home;
