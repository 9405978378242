/**
 * The input component which handles the input in the form, it recieves an
 * idm lable, type as props to be dynamic, and also the register and erros
 * from the useForm hook to handle the state
 */

import React from "react";

const Input = ({ id, label, type, register, errors }) => {
  return (
    <div className="flex flex-col mb-2">
      <div className="flex">
        <label htmlFor={id} className="mb-1 font-semibold w-2/6">
          {label} *
        </label>
        <input
          type={type}
          name={id}
          id={id}
          className="p-2 text-black w-4/6"
          {...register(id)}
        />
      </div>
      {errors[`${id}`] && (
        <p className="text-red-600">{errors[`${id}`]?.message}</p>
      )}
    </div>
  );
};

export default Input;
